<template>
  <calculator />
</template>

<script>
import Calculator from "@/components/Calculator.vue";
export default {
  components: { Calculator }
};
</script>

<style></style>
