<template>
  <div>
    <h2>Test</h2>

    <div class="grid grid-cols-2 gap-2">
      <div>Links</div>
      <div>Rechts</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
